
<template>
<div  v-loading="loading">   
<div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
              <el-table-column fixed align="center" label='PO Back Order #' prop="po_id" sortable width="160" >
                <template slot-scope="scope">
                <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewPurchase(scope.row.po_id,scope.row.order_status,scope.row.id)">{{(scope.row.back_order_number) ? scope.row.back_order_number : ""}}</a></el-tag>
                </template>
              </el-table-column>
              <el-table-column fixed align="center" label='PO Number' prop="po_number" sortable width="120" >
                <template slot-scope="scope">
                {{(scope.row.po_number) ? scope.row.po_number : ""}}
                </template>
              </el-table-column>
               <el-table-column label="Supplier" sortable  min-width="200">
                <template slot-scope="scope">
                <span>{{(scope.row.supplier) ? scope.row.supplier.company_name : ""}}</span>
                </template>
              </el-table-column>    
              <el-table-column label="Approved By"  width="200" >
                <template slot-scope="scope">
                <span>{{scope.row.created_by}}</span>
                </template>
              </el-table-column>            
              <el-table-column label="Created Date" prop="created_at" sortable width="190" >
                <template slot-scope="scope">
                {{scope.row.created_at|formatDate('DD MMM YYYY')}} 
                </template>
              </el-table-column>            
              <el-table-column label="Order Status" sortable width="200" >
                <template slot-scope="scope">                
                <span v-if="scope.row.order_status == 'processing'" class="active">Processing</span>
                <span v-if="scope.row.order_status == 'on-hold'" class="gray">On Hold</span>
                <span v-if="scope.row.order_status == 'completed'" class="active">Completed</span>
                <span v-if="scope.row.order_status == 'cancelled'" class="gray">Cancelled</span>
                <span v-if="scope.row.order_status == 'partial_process'" class="red">Partial Received</span>                
                </template>
              </el-table-column> 
              <el-table-column fixed="right" align="center" label="Actions" width="80">
                <template slot-scope="scope">
                  <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                      <el-dropdown-item :command="1"><i class="fa fa-eye"></i>View</el-dropdown-item>
                      <el-dropdown-item :command="2" v-if="scope.row.order_status == 'partial_process'" ><i class="fa fa-share-square"></i>Received PO</el-dropdown-item>
                      <el-dropdown-item :command="3"><i class="fa fa-times-circle"></i>Cancel Order</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>   
            </el-table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>

</div>
    
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getPurchaseBackorder} from "@/api/purchaseorder";
export default {
 name: "purchase-back_order",
    data() {
      return {
        loading :true,
        list: [],
        pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 10,
        },
      }
    },
 
 mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Purchase Orders", route: "/purchase-order/list" },
        { title: "Purchase Back Orders"}        
      ]);
    },

     methods:{
         fetchData(p){
            this.loading = true
            let params = { page: p }
            if(this.pagination.per_page){
              params.per_page = this.pagination.per_page;
            }
            getPurchaseBackorder(params).then(response => {
              this.list = response.data.data.data   
              this.pagination.current_page = response.data.data.current_page
              this.pagination.last_page = response.data.data.last_page
              this.pagination.total_rows = response.data.data.total
              this.loading =false;           
            })
         },
         handleSizeChange(per_page){
            this.pagination.per_page = per_page;
            this.fetchData(1);
          },
          handleActions(evt, row)
            {
              if (evt === 1){
                  this.$router.push({path:'/back-purchase-order/view/'+row.id})
              }
              else if (evt === 2){
                  this.$router.push({path:'/add/recive/purchase/order/'+row.po_id})
              }else if(evt === 3){
                this.$router.push({path:'/purchase/cancel/order/'+row.po_id})
              }
            },
             viewPurchase(purchase_id,status,backorder_id){
               if(status=='partial_process')
                this.$router.push({path:'/add/recive/purchase/order/'+purchase_id})
                else
                 this.$router.push({path:'/back-purchase-order/view/'+backorder_id})
            },
     },
      

     created() {
    this.fetchData(1);   
  },

}


</script>

<style>

</style>